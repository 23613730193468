export const prod_url = "https://yorumsepeti.co/"
const dev_url = process.env.DEV_URL + ":" + process.env.PORT + "/"

export const api_endpoint = process.env.NODE_ENV == "development" ? dev_url + "api" : prod_url + "api"  // Server side axioslarda gerekli.
export const site_img_path = "https://storage.googleapis.com/yorumsepeti/site_img/"
export const user_review_url_text = "-kullanici-yorumlari-"

export const lang = "tr"

// Google Analytcs
export const analytics_enabled = process.env.NODE_ENV == "development" ? false : true
export const gtag_id = "UA-133664679-2"

// Adsense
export const adsense_code = "ca-pub-1080205330301131"
export const adsense_enabled = process.env.NODE_ENV == "development" ? false : true

// Hotjar
export const hotjar_enabled = process.env.NODE_ENV == "development" ? false : true

// Sentry
export const sentry_dsn = "https://89ca589e60d2462fa70853d4da123492@o723475.ingest.sentry.io/5791963"